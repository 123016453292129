<template>
    <q-layout view="hhh lpr fff">
        <q-page-container>
            <q-page v-bind="$e.config.props.e_login_page" class="flex flex-center bg-gradient">
                <q-card v-bind="$e.config.props.e_login_card" style="width: 530px" class="shadow-10 q-pa-xl q-ma-md">
                    <q-card-section class="q-pa-none">
                        <q-img
                            :src="publicPath + 'assets/images/enforb.png'"
                            loading="lazy"
                            spinner-color="grey-7"
                            fit="contain"
                            style="height: 100px"
                            class="q-mb-xl"
                        />
                        <q-input
                            v-if="mode === LOGIN"
                            v-bind="$e.config.props.e_login_input"
                            label="Meno"
                            type="text"
                            v-model="userLogin"
                            :class="inputClass"
                            @keyup.enter="login"
                        >
                            <template v-slot:prepend>
                                <q-icon name="person" color="grey-7" class="icon" />
                            </template>
                        </q-input>
                        <q-input
                            v-if="mode === LOGIN || mode === PASSWORD_CHANGE"
                            v-bind="$e.config.props.e_login_input"
                            label="Heslo"
                            type="password"
                            v-model="userPassword"
                            :class="inputClass"
                            @keyup.enter="action"
                        >
                            <template v-slot:prepend>
                                <q-icon name="lock" color="grey-7" class="icon" />
                            </template>
                        </q-input>
                        <q-input
                            v-if="mode === PASSWORD_CHANGE"
                            v-bind="$e.config.props.e_login_input"
                            label="Overenie hesla"
                            type="password"
                            v-model="userPasswordConfirm"
                            :class="inputClass"
                            @keyup.enter="action"
                        >
                            <template v-slot:prepend>
                                <q-icon name="lock" color="grey-7" class="icon" />
                            </template>
                        </q-input>
                        <q-input
                            v-if="mode === PASSWORD_FORGOTTEN"
                            v-bind="$e.config.props.e_login_input"
                            label="Email"
                            type="text"
                            v-model="userMail"
                            :class="inputClass"
                            @keyup.enter="action"
                        >
                            <template v-slot:prepend>
                                <q-icon name="mail" color="grey-7" class="icon" />
                            </template>
                        </q-input>
                        <q-btn
                            v-bind="$e.config.props.e_login_button"
                            color="grey-7"
                            text-color="grey-3"
                            :label="buttonLabel"
                            unelevated
                            class="full-width q-pa-md q-mt-md q-mb-sm"
                            @click="action"
                        />
                        <q-btn
                            v-if="mode === LOGIN"
                            v-bind="$e.config.props.e_login_button"
                            color="grey-7"
                            text-color="grey-3"
                            label="Zabudnuté heslo"
                            unelevated
                            class="full-width q-pa-md q-mb-sm"
                            @click="mode = PASSWORD_FORGOTTEN"
                        />
                        <q-btn
                            v-if="mode !== LOGIN"
                            v-bind="$e.config.props.e_login_button"
                            color="grey-7"
                            text-color="grey-3"
                            label="Zrušiť"
                            unelevated
                            class="full-width q-pa-md q-mb-sm"
                            @click="cancel"
                        />
                    </q-card-section>
                </q-card>
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>
const LOGIN = 1;
const PASSWORD_CHANGE = 2;
const PASSWORD_FORGOTTEN = 3;

export default {
    name: 'LoginLayout',
    mounted() {
        setTimeout(() => (this.mounted = true), 1000);
    },
    data() {
        return {
            LOGIN: LOGIN,
            PASSWORD_CHANGE: PASSWORD_CHANGE,
            PASSWORD_FORGOTTEN: PASSWORD_FORGOTTEN,
            publicPath: process.env.BASE_URL,
            mounted: false,
            mode: LOGIN,
            userLogin: null,
            userPassword: null,
            userPasswordConfirm: null,
            userMail: null,
        };
    },
    computed: {
        inputClass() {
            return {
                'q-mb-sm': true,
                'autofill-fix': true,
                'autofill-fix-transition': this.mounted,
            };
        },
        buttonLabel() {
            switch (this.mode) {
                case LOGIN:
                    return 'Prihlásiť';
                case PASSWORD_CHANGE:
                    return 'Zmeniť heslo';
                case PASSWORD_FORGOTTEN:
                    return 'Poslať nové heslo';
                default:
                    return '';
            }
        },
    },
    methods: {
        action() {
            switch (this.mode) {
                case LOGIN:
                    this.login();
                    break;
                case PASSWORD_CHANGE:
                    this.changePassword();
                    break;
                case PASSWORD_FORGOTTEN:
                    this.sendPassword();
                    break;
            }
        },
        login() {
            this.$e.user.login(this.userLogin, this.userPassword, (user) => {
                if (typeof user === 'object') {
                    this.$router.login();
                } else if (user === 'PASSWORD_CHANGE') {
                    this.reset();
                    this.mode = PASSWORD_CHANGE;
                }
            });
        },
        changePassword() {
            this.$e.io.callMethod(
                'changeUserAccountPassword',
                {
                    'user-account-password': this.userPassword,
                    'user-account-password_confirm': this.userPasswordConfirm,
                },
                (user) => {
                    this.$e.user.setUser(user);
                    this.$router.login();
                }
            );
        },
        sendPassword() {
            this.$e.io.callMethod(
                'sendUserAccountPassword',
                {
                    'user-account-mail': this.userMail,
                },
                () => {
                    this.$e.notify.success(`Nové heslo bolo úspešne odoslané na email '${this.userMail}'.`);
                    this.reset();
                    this.mode = LOGIN;
                }
            );
        },
        cancel() {
            this.reset();
            this.mode = LOGIN;
        },
        reset() {
            this.userLogin = null;
            this.userPassword = null;
            this.userPasswordConfirm = null;
            this.userMail = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.bg-gradient {
    background: rgb(230, 230, 230);
    background: linear-gradient(315deg, rgb(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 100%);
}
.bg-rainbow {
    background: rgb(238, 174, 202);
    background: linear-gradient(135deg, rgba(238, 174, 202, 0.5) 0%, rgba(148, 187, 233, 0.5) 100%);
}

:deep() .q-field.autofill-fix .q-field__control input.q-field__native {
    filter: none !important;
}

:deep() .q-field.q-field--filled.autofill-fix.autofill-fix-transition .q-field__control input.q-field__native:-webkit-autofill {
    transition: box-shadow 0.36s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.36s cubic-bezier(0.4, 0, 0.2, 1);
}
:deep() .q-field.q-field--filled.autofill-fix .q-field__control input.q-field__native:-webkit-autofill {
    box-shadow: 0 0 0 1000px #f2f2f2 inset !important;
    -webkit-box-shadow: 0 0 0 1000px #f2f2f2 inset !important;
    margin-bottom: 1px;
}
:deep() .q-field.q-field--filled.autofill-fix .q-field__control:hover input.q-field__native:-webkit-autofill {
    box-shadow: 0 0 0 1000px #e6e6e6 inset !important;
    -webkit-box-shadow: 0 0 0 1000px #e6e6e6 inset !important;
}
:deep() .q-field.q-field--filled.autofill-fix.q-field--highlighted .q-field__control input.q-field__native:-webkit-autofill {
    box-shadow: 0 0 0 1000px #d5d5d5 inset !important;
    -webkit-box-shadow: 0 0 0 1000px #d5d5d5 inset !important;
}
</style>
